import { store } from "../App"
import fromCents from "../constants/localization/currencies/fromCents";

function logEvent(event, obj) {

    const user = (store.getState().user || {id: "", email: ""});

    obj = {
        ...obj,
        userId: (user.id || ""),
        userEmail: (user.email || ""),
        event: event,
    };

    obj = parseObj(obj);

    window.firebase.analytics().logEvent(event, obj);
}

function parseObj(obj) {

    if (!!obj) {
        const newObj = {};

        Object.keys(obj).map((key) => {
            let value = obj[key];

            if (typeof value === "boolean") {
                value = (value).toString();
            }

            newObj[key] = value;

            return null;
        });

        return newObj;
    }
    return {};
}

function logDataPackECommercePurchaseEvent(dataPackInstance) {
    const dataPack = dataPackInstance.dataPack;
    const transactionId = dataPackInstance.id;
    const id = `dp-${dataPack.id}_dpi-${dataPackInstance.id}`;
    const price = Number(fromCents(dataPack.amount, "NZD", false, "0.0"));
    const obj = {
        item_id: id,
        item_name: dataPack.name,
        quantity: 1,
        price: price,
        value: price,
        currency: "NZD",
        transaction_id: transactionId,
    };

    logEvent("ecommerce_purchase", obj);
}

export default {
    logEvent: logEvent,
    parseObj: parseObj,
    logDataPackECommercePurchaseEvent: logDataPackECommercePurchaseEvent,
};
