/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 9/04/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/


export default function callbackFunc(callbackFunc, r) {
    if (!!callbackFunc && typeof callbackFunc === 'function') {
        callbackFunc(r);
    }
}