/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 27/11/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import reducer from "../reducers/root";

export default function configureStore() {
    return createStore(
        reducer,
        compose(
            applyMiddleware(thunk),
        ),
    );
};
