/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


export default function bytesToHumanReadable(bytes) {
    let thresh = 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(0)  + units[u];
}