/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as DataUsageAPI from "./api/dataPacks";

export const SET_DATA_PACKS = "SET_DATA_PACKS";
export const SET_ACTIVE_DATA_PACKS = "SET_ACTIVE_DATA_PACKS";
export const ADD_ACTIVE_DATA_PACKS = "ADD_ACTIVE_DATA_PACKS";

export const setDataPacks = createAction(SET_DATA_PACKS, "dataPacks");
export const setActiveDataPacks = createAction(SET_ACTIVE_DATA_PACKS, "dataPacks");
export const addActiveDataPack = createAction(ADD_ACTIVE_DATA_PACKS, "dataPack");

export const getDataPacksForAccessPoint = wrapper(DataUsageAPI.getDataPacksForAccessPointAPI, (dispatch, r) => {
    dispatch(setDataPacks(r.dataPacks));
});

export const getActiveDataPacksForAccessPoint = wrapper(DataUsageAPI.getActiveDataPacksForAccessPointAPI, (dispatch, r) => {
    dispatch(setActiveDataPacks(r.dataPacks));
});

export const buyDataPackWithToken = wrapper(DataUsageAPI.buyDataPackWithTokenAPI, (dispatch, r) => {
    dispatch(addActiveDataPack(r));
});