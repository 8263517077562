import Dinero from "dinero.js";

export default function fromCents(amount, currencyCode = "NZD", pretty) {

    let format = "0";

    if (pretty) {
        format = "$0,0.00";
    }

    return Dinero({amount: amount, currency: currencyCode}).toFormat(format);
};
