/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import styles from "./Loader.module.scss";
import ReactLoading from "react-loading";
import omit from "lodash/omit";

Loader.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,

    large: PropTypes.bool,
    center: PropTypes.bool,
};

Loader.defaultProps = {
    className: null,
    type: "bubbles",
    height: 36,
    width: 36,
    color: "black",

    large: false,
    center: true,
};

export default function Loader(props) {
    const className = classnames(styles.loader, props.className);

    if (props.large) {
        props.height = 50;
        props.width = 50;
    }

    const omittedProps = omit({...props}, ["large", "center"]);

    const Loading = <ReactLoading {...omittedProps} className={className}/>;


    if (props.center) {
        return (
            <div className={styles.loader_center}>
                {Loading}
            </div>
        );
    }

    return Loading;
}
