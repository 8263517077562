/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/12/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router";
import Loading from "./components/routes/loading/Loading";
import Purchase from "./components/routes/purchase/Purchase";
import DataPacks from "./components/routes/dataPacks/DataPacks";
import Landing from "./components/routes/landing/Landing";
import {getAccessPoint, getAccessPointIp} from "./redux/actions/accessPoint";
import InvalidAccessPoint from "./components/routes/invalidAccessPoint/InvalidAccessPoint";
import {BrowserRouter as Router} from "react-router-dom";

class Main extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };

        props.dispatch(getAccessPointIp({
            onSuccess: (ip) => {
                props.dispatch(getAccessPoint({
                    ip: ip,
                    onSuccess: () => {
                        this.setState({
                            loading: false,
                        });
                    },
                    onError: this.onError,
                }));
            },
            onError: this.onError,
        }));
    }

    onError = () => {
        this.setState({
            loading: false,
        });
    };

    render() {
        return (
            <Router>
                <Switch>
                    {this.state.loading ? <Route path={"*"} component={Loading}/> : null}
                    {!this.props.accessPoint ? <Route path={"*"} component={InvalidAccessPoint}/> : null}

                    <Route exact path={"/data-packs/purchase/:dataPackId"} component={Purchase}/>
                    <Route path={"/data-packs"} component={DataPacks}/>
                    <Route path={"*"} component={Landing}/>

                </Switch>
            </Router>
        );
    }
}

export default Main = connect((state) => {
    return {
        accessPoint: state.accessPoint
    };
})(Main);