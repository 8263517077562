/**
 * Created by Chris on 2/02/17.
 */


import {combineReducers} from "redux";
import accessPoint from "./accessPoint";
import dataPacks from "./dataPacks";

//Combing the reducers they must have a lower case.
export default combineReducers({
    accessPoint: accessPoint,
    dataPacks: dataPacks,
});
