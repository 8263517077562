/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./TextInput.module.scss";
import classnames from "classnames";
import Loader from "../loader/Loader";

export default class TextInput extends Component {

    static propTypes = {
        className: PropTypes.string,
        classNameWrapper: PropTypes.string,

        inputComponent: PropTypes.any,

        error: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        defaultValue: PropTypes.string,
        helperText: PropTypes.string,
        maxLength: PropTypes.number,
        autoComplete: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        dollar: PropTypes.bool,
        password: PropTypes.bool,
        onTextChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,

        formatOutput: PropTypes.func,
        formatInput: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        classNameWrapper: null,
        inputComponent: null,
        error: null,
        label: null,
        placeholder: "",
        defaultValue: null,
        helperText: null,
        maxLength: null,
        autoComplete: null,
        loading: false,
        disabled: false,
        dollar: false,
        password: false,
        onTextChange: () => null,
        onFocus: () => null,
        onBlur: () => null,

        formatOutput: (t) => t,
        formatInput: (t) => t,
    };

    state = {
        focused: false,
    };

    onFocus = () => {
        this.props.onFocus();
        if (!!this.props.input) {
            this.props.input.onFocus();
        }

        this.setState({
            focused: true,
        });
    };

    onBlur = () => {
        this.props.onBlur();
        if (!!this.props.input) {
            this.props.input.onBlur();
        }

        this.setState({
            focused: false,
        });
    };

    onTextChange = (e) => {

        const target = e.target;
        let text = null;
        if (!!target) {
            text = target.value;
        }

        this.props.onTextChange(text, e);

        if (this.props.dollar) {
            text = text.replace(/\D+/g, "");
        }

        if (this.props.formatOutput) {
            text = this.props.formatOutput(text);
        }

        if (!!this.props.input) {
            this.props.input.onChange(text);
        }

    };

    focus = () => {
        !!this.input && this.input.focus();
    };

    blur = () => {
        !!this.input && this.input.blur();
    };

    render() {

        const className = classnames(styles.text_input, this.props.className);

        let text = this.props.defaultValue || this.props.text;

        if (!!this.props.input) {
            text = this.props.input.value;
        }

        if (this.props.formatInput) {
            text = this.props.formatInput(text);
        }

        let error = this.props.error;

        if (!!this.props.meta && !!this.props.meta.error) {
            error = this.props.meta.error;
        }

        const inputWrapperClassName = classnames(styles.input_wrapper, {
            [styles.focused]: this.state.focused,
            [styles.disabled]: this.props.disabled,
            [styles.error]: !!error,
        }, this.props.classNameWrapper);

        const Component = !!this.props.inputComponent ? this.props.inputComponent : `input`;

        const hasTextError = (!!error && error !== " ");

        return (
            <div className={className}>
                {!!this.props.label ? <label className={styles.label}>{this.props.label}</label> : null}

                <div className={inputWrapperClassName} ref={(ref) => this.inputWrapper = ref}>
                    <Component placeholder={this.props.placeholder}
                               onFocus={this.onFocus}
                               onBlur={this.onBlur}
                               onChange={this.onTextChange}
                               value={text}
                               disabled={this.props.disabled}
                               type={this.props.password ? "password" : undefined}
                               ref={(ref) => this.input = ref}
                    />

                    {this.props.loading ?
                        <div className={styles.loading}>
                            <Loader height={24}
                                    width={24}
                                    color={"#454545"}
                                    type={"spinningBubbles"}/>
                        </div> : null}
                </div>

                {!!hasTextError ? <label className={styles.error_text}>{error}</label> : !!this.props.helperText ? <label className={styles.helper_text}>{this.props.helperText}</label> : null}
            </div>
        );
    }
}