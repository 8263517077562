/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 01/12/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/


export default function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}