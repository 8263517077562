/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/07/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

const API_URL = process.env.REACT_APP_API_URL;

export const API = API_URL;

export default function fetchJSON(method, url, token, data) {
    return new Promise((resolve, reject) => {
        url = API + url;

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        };

        let body = null;

        if (method !== "get" && data != null) {
            if (data.isformdata) {
                body = data;
                delete headers["Content-Type"]
            } else {
                body = JSON.stringify(data);
            }
        }

        const request = new Request(url, {
            method: method,
            headers: headers,
            body: body,
        });

        fetch(request).then((resp) => {
            return resp.json().then((json) => {
                return resp.ok ? resolve(json) : reject(json)
            }).catch((err) => {
                reject({...err, url: url});
            })
        }).catch((err) => {
            reject({...err, url: url});
        });
    });
}