/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 28/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Type from "../actions/dataPacks";

const initialState = {
    available: [],
    active: [],
};

export default function dataPack(state = initialState, action = {type: null, dataPacks: null}) {
    switch (action.type) {
        case Type.SET_DATA_PACKS: {
            return {
                ...state,
                available: action.dataPacks
            };
        }
        case Type.SET_ACTIVE_DATA_PACKS: {
            return {
                ...state,
                active: action.dataPacks
            };
        }
        case Type.ADD_ACTIVE_DATA_PACKS: {
            return {
                ...state,
                active: [
                    ...state.active,
                    action.dataPack,
                ]
            };
        }
        default: {
            return state;
        }
    }
}
