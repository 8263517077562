/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/12/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import styles from "./InvalidAccessPoint.module.scss";
import Paper from "@material-ui/core/Paper/Paper";

export default class InvalidAccessPoint extends Component {

    render() {
        return (
            <div className={styles.invalid_access_point}>
                <div className={styles.content}>
                    <Paper className={styles.header}>
                        <h2>Whoops!</h2>
                        <h4>Please connect to a Campable WiFi Connect device.</h4>
                    </Paper>
                </div>
            </div>
        );
    }
}