/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { Component } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import jssPreset from '@material-ui/core/styles/jssPreset';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import JssProvider from 'react-jss/lib/JssProvider';
import Jss from 'jss/lib/Jss';
import * as Colors from "./constants/colors";
import configureStore from "./redux/store/configureStore";
import { Provider } from "react-redux";
import styles from "./App.module.scss";
import CampableLogo from "./resources/js/CampableLogo";
import { StripeProvider } from 'react-stripe-elements';
import Main from "./Main";

const styleNode = document.createComment("insertion-point-jss");
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = new Jss(jssPreset());
jss.options.insertionPoint = "insertion-point-jss";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            light: Colors.PRIMARY_LIGHT,
            main: Colors.PRIMARY,
            dark: Colors.PRIMARY_DARK,
            contrastText: '#fff',
        },
        secondary: {
            light: Colors.SECONDARY_LIGHT,
            main: Colors.SECONDARY,
            dark: Colors.SECONDARY_DARK,
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: 'bold'
            },
        },
    },
});

export const store = configureStore();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

window.firebase.initializeApp(firebaseConfig);
window.firebase.analytics();

class App extends Component {
    render() {
        return (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Provider store={store}>
                    <JssProvider jss={jss} generateClassName={generateClassName}>
                        <MuiThemeProvider theme={theme}>
                            <CssBaseline/>
                            <header className={styles.header}>
                                <CampableLogo className={styles.campable_logo}/>
                            </header>
                            <main className={styles.main}>
                                <Main/>
                            </main>
                        </MuiThemeProvider>
                    </JssProvider>
                </Provider>
            </StripeProvider>
        );
    }
}

export default App;
