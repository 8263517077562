/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 28/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import get from "./util/get";
import put from "./util/put";

export function getAccessPointAPI(token, ip) {
    return get(`/v1/access-points/ip/${ip}`);
}

export function updateAccessPointAPI(token, data) {
    return put(`/v1/access-points`, token, data);
}

export function getAccessPointIpAddressAPI() {

    const timeout = new Promise((resolve, reject) => {
        setTimeout(reject, 5000, "Request timed out");
    });

    const request = new Promise(((resolve, reject) => {
        fetch("https://cp.campable.com/my-ip").then((resp) => {
            resp.text().then(resolve).catch(reject);
        }).catch(reject);
    }));

    return Promise.race([timeout, request]);
}