/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import {connect} from "react-redux";
import styles from "./DataPacks.module.scss";
import {getDataPacksForAccessPoint} from "../../../redux/actions/dataPacks";
import Loading from "../loading/Loading";
import bytesToHumanReadable from "../../../constants/bytesToHumanReadable";
import moment from "moment";
import Paper from "@material-ui/core/Paper/Paper";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import sortBy from "lodash/sortBy";
import fromCents from "../../../constants/localization/currencies/fromCents";

class DataPacks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };

        props.dispatch(getDataPacksForAccessPoint({
            bssid: props.accessPoint.id,
            onSuccess: () => {
                this.setState({
                    loading: false,
                });
            },
        }));
    }


    renderDataPacks = () => {
        return (
            sortBy(this.props.dataPacks, [(dp) => dp.bytes]).map(this.renderDataPackRow)
        );
    };

    renderDataPackRow = (dataPack, i) => {

        const readableBytes = bytesToHumanReadable(dataPack.bytes);
        const duration = moment.duration(dataPack.validityPeriod, "minutes");
        const validFor = duration.humanize();
        const price = fromCents(dataPack.amount, dataPack.currency, true);

        return (
            <Paper className={styles.data_pack_row_wrapper} key={i}>
                <MenuItem className={styles.data_pack_row} onClick={() => this.onDataPackRowPress(dataPack)}>
                    <h4 className={styles.row_header}>{dataPack.name}</h4>
                    <div className={styles.row}>
                        <h5 className={styles.row_label}>Data amount</h5>
                        <h5 className={styles.row_value}>{readableBytes}</h5>
                    </div>
                    <div className={styles.row}>
                        <h5 className={styles.row_label}>Valid for</h5>
                        <h5 className={styles.row_value}>{validFor}</h5>
                    </div>
                    <div className={styles.row}>
                        <h5 className={styles.row_label}>Price</h5>
                        <h5 className={styles.row_value}>{price}</h5>
                    </div>
                </MenuItem>
            </Paper>
        );
    };

    onDataPackRowPress = (dp) => {
        this.props.history.push(`/data-packs/purchase/${dp.id}`);
    };

    render() {
        return (
            <div className={styles.data_packs}>
                <div className={styles.content}>
                    <Paper className={styles.header}>
                        <h2>Data Packs</h2>
                        <h4>Select a data pack to buy.</h4>
                    </Paper>
                    {this.state.loading ? <Loading/> : this.renderDataPacks()}
                </div>
            </div>
        );
    }
}

export default DataPacks = connect((state) => {
    return {
        accessPoint: state.accessPoint,
        dataPacks: state.dataPacks.available,
    };
})(DataPacks);