/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import styles from "./Loading.module.scss";
import Loader from "../../widgets/loader/Loader";

export default class Loading extends Component {

    render() {
        return (
            <div className={styles.loading}>
                <Loader width={64} height={64}/>
            </div>
        );
    }
}
