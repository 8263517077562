/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 9/04/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/


import callbackFunc from "./callbackFunc";
import errorFunc from "./errorFunc";
import values from "lodash/values";

export default function wrapper(promise, onDispatchSuccess, onDispatchFailure) {
    return ({onSuccess, onError, ...params} = {}) => {
        return async (dispatch) => {

            promise(null, ...values(params)).then((r) => {
                console.log("API SUCCESS: ", r);
                if (!!onDispatchSuccess) {
                    onDispatchSuccess(dispatch, r, ...values(params));
                }
                callbackFunc(onSuccess, r, params);
            }).catch((err) => {
                console.log("API FAILURE: ", err);
                if (!!onDispatchFailure) {
                    onDispatchFailure(dispatch, err, ...values(params));
                }
                errorFunc(onError, err, params);
            });
        }
    }
}