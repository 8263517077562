/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.module.scss';
import "airbnb-browser-shims";
import "airbnb-js-shims";

ReactDOM.render(<App />, document.getElementById('root'));

