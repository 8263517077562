/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/07/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import fetchJSON from "./fetchJSON";

export default function put(url, token, data) {
    return fetchJSON('put', url, token, data);
}