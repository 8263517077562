/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 28/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./CreditCardForm.module.scss";
import classnames from "classnames";
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from "react-stripe-elements";
import Button from "../button/Button";
import PoweredByStripe from "../../../resources/js/PoweredByStripe";
import TextInput from "../textInput/TextInput";
import validateEmail from "../../../constants/validateEmail";
import Collapse from "@material-ui/core/Collapse";
import Loading from "../../routes/loading/Loading";
import {connect} from "react-redux";
import {buyDataPackWithToken} from "../../../redux/actions/dataPacks";
import {withRouter} from "react-router";
import Logging from "../../../utils/logging";

class CreditCardForm extends Component {

    static propTypes = {
        className: PropTypes.string,

        onSuccess: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: null,

        onSuccess: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,

            email: null,

            emailError: null,
            cardNumberError: null,
            cardExpiryError: null,
            cardCVVError: null,
        };
    }

    onSubmit = () => {

        if (!this.state.email || !validateEmail(this.state.email)) {
            this.setState({
                error: "Email address is badly formatted",
                emailError: " ",
            });
        } else {

            this.setState({
                emailError: null,
                cardNumberError: null,
                cardExpiryError: null,
                cardCVVError: null,
                loading: true
            });

            this.props.stripe.createToken({
                name: this.state.email,
            }).then((r) => {
                if (!!r.error) {
                    switch (r.error.code) {
                        case "incomplete_number": {
                            this.setState({
                                loading: false,
                                error: r.error.message,
                                cardNumberError: " ",
                            });
                            break;
                        }
                        case "incomplete_expiry": {
                            this.setState({
                                loading: false,
                                error: r.error.message,
                                cardExpiryError: " ",
                            });
                            break;
                        }
                        case "incomplete_cvc": {
                            this.setState({
                                loading: false,
                                error: r.error.message,
                                cardCVVError: " ",
                            });
                            break;
                        }

                        default: {
                            this.setState({
                                loading: false,
                                error: r.error.message,
                            });
                            break;
                        }
                    }
                } else {
                    console.log("onSuccess", r);
                    const token = r.token.id;
                    console.log("token", token);
                    this.onStripeTokenSuccess(token);
                }
            }).catch((err) => {
                console.log("errrr1111", err)
            });
        }
    };

    onStripeTokenSuccess = (token) => {
        this.props.dispatch(buyDataPackWithToken({
            token: token,
            data: {
                accessPointId: this.props.accessPoint.id,
                dataPackId: this.props.dataPackId,
                email: this.state.email,
            },

            onSuccess: (dataPackInstance) => {
                Logging.logDataPackECommercePurchaseEvent(dataPackInstance);
                this.props.history.replace("/");
            },
            onError: (err) => {
                console.log("errrr", err);
                this.setState({
                    loading: false,
                    error: err.message,
                })
            },
        }));
    };

    onEmailTextChange = (text) => {
        this.setState({
            emailError: null,
            email: text,
        })
    };

    onCardNumTextChange = () => {
        this.setState({
            cardNumberError: null,
        });
    };

    onCardExpTextChange = () => {
        this.setState({
            cardExpiryError: null,
        });
    };

    onCardCVVTextChange = () => {
        this.setState({
            cardCVVError: null,
        });
    };

    render() {
        const className = classnames(styles.credit_card_dialog, this.props.className);

        return (
            <div className={className}>

                <Collapse in={!this.state.loading}>
                    <div className={styles.form}>

                        <TextInput className={styles.cc_number}
                                   onTextChange={this.onEmailTextChange}
                                   error={this.state.emailError}
                                   label={"Email"}/>


                        <TextInput className={styles.cc_number}
                                   classNameWrapper={styles.stripe_text_input_wrapper}
                                   onTextChange={this.onCardNumTextChange}
                                   error={this.state.cardNumberError}
                                   inputComponent={CardNumberElement}
                                   label={"Card number"}/>

                        <div className={styles.expiry_cvv_wrapper}>
                            <TextInput className={styles.expiry}
                                       classNameWrapper={styles.stripe_text_input_wrapper}
                                       onTextChange={this.onCardExpTextChange}
                                       error={this.state.cardExpiryError}
                                       inputComponent={CardExpiryElement}
                                       label={"Expiry"}/>


                            <TextInput className={styles.cvv}
                                       classNameWrapper={styles.stripe_text_input_wrapper}
                                       onTextChange={this.onCardCVVTextChange}
                                       error={this.state.cardCVVError}
                                       inputComponent={CardCVCElement}
                                       label={"CVV"}/>
                        </div>

                        <label className={styles.error_text}>{this.state.error}</label>

                        <Button className={styles.submit_button} onPress={this.onSubmit}>Pay Now</Button>

                    </div>
                </Collapse>
                <Collapse in={this.state.loading}>
                    <Loading/>
                </Collapse>
            </div>
        );
    }
}

CreditCardForm = injectStripe(connect((state, props) => {
    const dataPackId = Number(props.match.params.dataPackId);
    const dataPacks = (state.dataPacks.available || []);
    const dataPack = dataPacks.filter((dataPack) => dataPack.id === dataPackId)[0];
    return {
        accessPoint: state.accessPoint,
        dataPackId: dataPackId,
        dataPack: dataPack,
        dataPacks: dataPacks,
    };
})(CreditCardForm));

export default CreditCardForm = withRouter(CreditCardForm);

