/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component, Fragment} from "react";
import styles from "./Landing.module.scss";
import Button from "../../widgets/button/Button";
import {getActiveDataPacksForAccessPoint} from "../../../redux/actions/dataPacks";
import connect from "react-redux/es/connect/connect";
import Loading from "../loading/Loading";
import Paper from "@material-ui/core/Paper/Paper";
import bytesToHumanReadable from "../../../constants/bytesToHumanReadable";
import moment from "moment";

class Landing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };

        props.dispatch(getActiveDataPacksForAccessPoint({
            id: props.accessPoint.id,
            onSuccess: () => {
                this.setState({
                    loading: false,
                });
            },
        }));
    }

    onBuyMorePress = () => {
        this.props.history.push("/data-packs");
    };

    renderLoadingView = () => {
        return (
            <Loading/>
        );
    };

    renderDataViews = () => {

        const activeDataPacks = this.props.activeDataPacks;

        const dataPacksWithData = activeDataPacks.filter((dp) => {
            const bytesRemaining = (dp.dataPack.bytes - dp.usedBytes);
            return (bytesRemaining !== 0)
        });

        return (
            <Fragment>
                {dataPacksWithData.length === 0 ? this.renderNoDataView() : this.renderDataHeader()}
                {this.renderActiveDataPacksView()}
            </Fragment>
        );
    };

    renderNoDataView = () => {
        return (
            <div className={styles.no_data_content}>
                <h1 className={styles.header_text}>Looks like you’ve run out of data</h1>
                <div className={styles.buy_more_button_wrapper}>
                    <Button className={styles.buy_more_button} onPress={this.onBuyMorePress}>Buy more data</Button>
                </div>
            </div>
        );
    };

    renderDataHeader = () => {
        return (
            <Paper className={styles.header}>
                <h2>Data packs</h2>
                <div>
                    <Button className={styles.buy_more_button} onPress={this.onBuyMorePress}>Buy more data</Button>
                </div>
            </Paper>
        );
    };

    renderActiveDataPacksView = () => {
        return (
            <div className={styles.data_packs_content}>
                {this.props.activeDataPacks.map(this.renderActiveDataPackRow)}
            </div>
        );
    };

    renderActiveDataPackRow = (dp, i) => {

        const bytesRemaining = (dp.dataPack.bytes - dp.usedBytes);
        const readableBytes = bytesToHumanReadable(dp.dataPack.bytes);
        const readableBytesUsed = bytesToHumanReadable(bytesRemaining);
        const dataExpiryDate = moment(dp.expiry).format("DD/MM/YYYY");

        return (
            <Paper className={styles.active_data_pack_row} key={i}>
                <h4 className={styles.row_header}>{dp.dataPack.name}</h4>
                <div className={styles.row}>
                    <h5 className={styles.row_label}>Data remaining</h5>
                    <h5 className={styles.row_value}>{readableBytesUsed} (of {readableBytes})</h5>
                </div>
                <div className={styles.row}>
                    <h5 className={styles.row_label}>Daily data expiry date</h5>
                    <h5 className={styles.row_value}>{dataExpiryDate}</h5>
                </div>
            </Paper>
        );
    };

    render() {
        return (
            <div className={styles.landing}>
                <div className={styles.content}>
                    {this.state.loading ? this.renderLoadingView() : this.renderDataViews()}
                </div>
            </div>
        );
    }
}

export default Landing = connect((state) => {
    return {
        accessPoint: state.accessPoint,
        activeDataPacks: state.dataPacks.active,
    };
})(Landing);
