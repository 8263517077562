/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 9/04/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/

import values from "lodash/values";

export default function errorFunc(errorFunc, r, props) {
    if (!!errorFunc && typeof errorFunc === 'function') {
        errorFunc(r, ...values(props));
    }
}