/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


export const PRIMARY_LIGHT = "#96ff54";
export const PRIMARY = "#5fce17";
export const PRIMARY_DARK = "#1c9c00";

export const SECONDARY_LIGHT = "#39796b";
export const SECONDARY = "#004d40";
export const SECONDARY_DARK = "#00463b";

export const GREY = "#F2F2F2";
export const GREY_DARK = "#222222";
export const GREY_LIGHT = "#EFEFEF";
export const RED = "#FF4338";
export const BLUE = "#2196F3";
export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const TRANSPARENT = "transparent";