/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import createAction from "./helpers/createAction";
import * as AccesspointAPI from "./api/accessPoint";
import wrapper from "./helpers/wrapper";

export const SET_ACCESS_POINT = "SET_ACCESS_POINT";

export const setAccessPoint = createAction(SET_ACCESS_POINT, "accessPoint");

export const getAccessPointIp = wrapper(AccesspointAPI.getAccessPointIpAddressAPI);

export const getAccessPoint = wrapper(AccesspointAPI.getAccessPointAPI, (dispatch, accessPoint) => {
    dispatch(setAccessPoint(accessPoint));
});

export const updateAccessPoint = wrapper(AccesspointAPI.updateAccessPointAPI, (dispatch, accessPoint) => {
    dispatch(setAccessPoint(accessPoint));
});