/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Type from "../actions/accessPoint";

const initialState = null;

export default function accessPoint(state = initialState, action = {type: null, accessPoint: null}) {
    switch (action.type) {
        case Type.SET_ACCESS_POINT: {
            console.log("action", action)
            return action.accessPoint;
        }
        default: {
            return state;
        }
    }
}