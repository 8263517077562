/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import {connect} from "react-redux";
import styles from "./Purchase.module.scss";
import Paper from "@material-ui/core/Paper/Paper";
import CreditCardForm from "../../widgets/creditCardForm/CreditCardForm";
import {Elements} from "react-stripe-elements";
import {getDataPacksForAccessPoint} from "../../../redux/actions/dataPacks";
import bytesToHumanReadable from "../../../constants/bytesToHumanReadable";
import moment from "moment";
import Loading from "../loading/Loading";
import Button from "../../widgets/button/Button";
import PoweredByStripe from "../../../resources/js/PoweredByStripe";
import fromCents from "../../../constants/localization/currencies/fromCents";

class Purchase extends Component {


    constructor(props) {
        super(props);

        if (!props.dataPack) {

            this.state = {
                loading: true,
            };

            props.dispatch(getDataPacksForAccessPoint({
                bssid: props.accessPoint.id,
                onSuccess: () => {
                    this.setState({
                        loading: false,
                    });
                },
            }));

        } else {
            this.state = {
                loading: false,
            };
        }

    }

    renderContent = () => {

        const dataPack = (this.props.dataPack || {});
        const readableBytes = bytesToHumanReadable(dataPack.bytes);
        const duration = moment.duration(dataPack.validityPeriod, "minutes");
        const validFor = duration.humanize();
        const price = fromCents(dataPack.amount, dataPack.currency, true);

        return (
            <Paper className={styles.card}>
                <div className={styles.header}>
                    <h2>{dataPack.name}</h2>
                    <div className={styles.row}>
                        <h5 className={styles.row_label}>Data amount</h5>
                        <h5 className={styles.row_value}>{readableBytes}</h5>
                    </div>
                    <div className={styles.row}>
                        <h5 className={styles.row_label}>Valid for</h5>
                        <h5 className={styles.row_value}>{validFor}</h5>
                    </div>
                    <div className={styles.row}>
                        <h5 className={styles.row_label}>Price</h5>
                        <h5 className={styles.row_value}>{price}</h5>
                    </div>
                </div>

                <div className={styles.form}>
                    <Elements>
                        <CreditCardForm />
                    </Elements>
                </div>

                <div className={styles.legal}>
                    <Button plain slab href={"https://campable.com/privacy-policy"} target={"_blank"} rel={"noopener noreferrer"}>Privacy Policy</Button>
                    <Button plain slab href={"https://campable.com/terms-and-conditions"} target={"_blank"} rel={"noopener noreferrer"}>Terms & Conditions</Button>
                </div>

                <div className={styles.powered_by_stripe_wrapper}>
                    <a href={"http://stripe.com"} target={"_blank"} rel={"noopener noreferrer"}>
                        <PoweredByStripe/>
                    </a>
                </div>
            </Paper>
        );
    };

    render() {
        return (
            <div className={styles.purchase}>
                {this.state.loading ? <Loading/> : this.renderContent()}
            </div>
        );
    }
}

export default Purchase = connect((state, props) => {

    const dataPackId = Number(props.match.params.dataPackId);
    const dataPacks = (state.dataPacks.available || []);
    const dataPack = dataPacks.filter((dataPack) => dataPack.id === dataPackId)[0];

    return {
        accessPoint: state.accessPoint,
        dataPackId: dataPackId,
        dataPack: dataPack,
        dataPacks: dataPacks,
    };
})(Purchase);