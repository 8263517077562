/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import get from "./util/get";
import post from "./util/post";

export function getDataPacksForAccessPointAPI(token, id) {
    return get(`/v1/access-points/${id}/available-data-packs`, token);
}

export function getActiveDataPacksForAccessPointAPI(token, id) {
    return get(`/v1/access-points/${id}/data-packs`);
}

export function buyDataPackWithTokenAPI(token, stripeToken, data) {
    return post(`/v1/data-packs/purchase/token/${stripeToken}`, token, data);
}