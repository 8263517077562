/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {Component} from 'react';
import MaterialButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import classnames from "classnames";
import omit from "lodash/omit";

export default class Button extends Component {

    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),

        onPress: PropTypes.func,
        color: PropTypes.oneOf([
            "default",
            "primary",
            "secondary"
        ]),
        disabled: PropTypes.bool,
        round: PropTypes.bool,
        wrap: PropTypes.bool,
        dark: PropTypes.bool,
        noPadding: PropTypes.bool,
        plain: PropTypes.bool,
        slab: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        classes: [],

        onPress: null,
        color: "primary",
        disabled: false,
        round: true,
        wrap: false,
        dark: false,
        noPadding: false,
        plain: false,
        slab: false,
    };

    render() {
        const className = classnames(styles.button, {
            [styles.round]: this.props.round,
            [styles.wrap]: this.props.wrap,
            [styles.dark]: (this.props.color === "secondary" || this.props.dark),
            [styles.noPadding]: this.props.noPadding,
            [styles.plain]: this.props.plain,
            [styles.slab]: this.props.slab,
        }, this.props.className, this.props.classes);


        const props = omit({...this.props}, ["classes", "onPress", "round", "wrap", "dark", "noPadding", "plain", "slab"]);

        if (this.props.dark) {
            props.color = "secondary";
        }


        return (
            <MaterialButton variant="contained"
                            {...props}
                            onClick={this.props.onPress}
                            className={className}>
                {this.props.children}
            </MaterialButton>
        );
    }
}