/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import PropTypes from "prop-types";

export default function CampableLogo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 160 34.476" {...props}>
            <defs>
                <style>
                    {`
                    .campable-logo-1 {
                    fill: none;
                }

                    .campable-logo-2 {
                    clip - path: url(#clip-path);
                }

                    .campable-logo-3 {
                    fill: #004d40;
                }

                    .campable-logo-4 {
                    fill: #5fce17;
                }`}
                </style>
                <clipPath id="clip-path">
                    <rect className="campable-logo-1" width="160" height="34.476"/>
                </clipPath>
            </defs>
            <g id="Repeat_Grid_1" data-name="Repeat Grid 1" className="campable-logo-2">
                <g id="Group_6217" data-name="Group 6217">
                    <g id="Group_6216" data-name="Group 6216" transform="translate(40.44 5.975)">
                        <g id="Group_6215" data-name="Group 6215">
                            <g id="Group_6214" data-name="Group 6214">
                                <g id="Group_6199" data-name="Group 6199" transform="translate(53.215 4.604)">
                                    <rect id="Rectangle_3934" data-name="Rectangle 3934" className="campable-logo-3" width="3.246" height="19.47"/>
                                </g>
                                <g id="Group_6200" data-name="Group 6200" transform="translate(53.215 4.604)">
                                    <path id="Path_27350" data-name="Path 27350" className="campable-logo-3"
                                          d="M547.771,64.6a4.056,4.056,0,1,1-4.056,4.056,4.059,4.059,0,0,1,4.056-4.056m0-3.246a7.3,7.3,0,1,0,7.3,7.3,7.3,7.3,0,0,0-7.3-7.3Z"
                                          transform="translate(-540.47 -61.35)"/>
                                </g>
                                <g id="Group_6201" data-name="Group 6201" transform="translate(24.464 4.605)">
                                    <rect id="Rectangle_3935" data-name="Rectangle 3935" className="campable-logo-3" width="3.246" height="14.602" transform="translate(3.246 14.602) rotate(180)"/>
                                </g>
                                <g id="Group_6202" data-name="Group 6202" transform="translate(29.001 4.605)">
                                    <rect id="Rectangle_3936" data-name="Rectangle 3936" className="campable-logo-3" width="3.246" height="14.602" transform="translate(3.246 14.602) rotate(180)"/>
                                </g>
                                <g id="Group_6203" data-name="Group 6203" transform="translate(13.108 4.604)">
                                    <path id="Path_27351" data-name="Path 27351" className="campable-logo-3"
                                          d="M317.551,64.6a4.056,4.056,0,1,1-4.056,4.056,4.059,4.059,0,0,1,4.056-4.056m0-3.246a7.3,7.3,0,1,0,7.3,7.3,7.3,7.3,0,0,0-7.3-7.3Z"
                                          transform="translate(-310.25 -61.35)"/>
                                </g>
                                <g id="Group_6204" data-name="Group 6204" transform="translate(79.518 4.605)">
                                    <rect id="Rectangle_3937" data-name="Rectangle 3937" className="campable-logo-3" width="3.246" height="14.602" transform="translate(3.246 14.602) rotate(180)"/>
                                </g>
                                <g id="Group_6205" data-name="Group 6205" transform="translate(68.162 4.604)">
                                    <path id="Path_27352" data-name="Path 27352" className="campable-logo-3"
                                          d="M633.571,64.6a4.056,4.056,0,1,1-4.056,4.056,4.059,4.059,0,0,1,4.056-4.056m0-3.246a7.3,7.3,0,1,0,7.3,7.3,7.3,7.3,0,0,0-7.3-7.3Z"
                                          transform="translate(-626.27 -61.35)"/>
                                </g>
                                <g id="Group_6206" data-name="Group 6206" transform="translate(106.579 10.425)">
                                    <path id="Path_27353" data-name="Path 27353" className="campable-logo-3" d="M846.79,94.76v2.995h12.695l-2.516-2.995Z" transform="translate(-846.79 -94.76)"/>
                                </g>
                                <g id="Group_6207" data-name="Group 6207" transform="translate(84.723)">
                                    <path id="Path_27354" data-name="Path 27354" className="campable-logo-3" d="M721.33,35.707V54.129h3.246V34.92A31.344,31.344,0,0,1,721.33,35.707Z"
                                          transform="translate(-721.33 -34.92)"/>
                                </g>
                                <g id="Group_6208" data-name="Group 6208" transform="translate(100.515 0.834)">
                                    <rect id="Rectangle_3938" data-name="Rectangle 3938" className="campable-logo-3" width="3.246" height="18.374"/>
                                </g>
                                <g id="Group_6209" data-name="Group 6209" transform="translate(84.723 4.604)">
                                    <path id="Path_27355" data-name="Path 27355" className="campable-logo-3"
                                          d="M728.631,64.6a4.056,4.056,0,1,1-4.056,4.056,4.059,4.059,0,0,1,4.056-4.056m0-3.246a7.3,7.3,0,1,0,7.3,7.3,7.3,7.3,0,0,0-7.3-7.3Z"
                                          transform="translate(-721.33 -61.35)"/>
                                </g>
                                <g id="Group_6210" data-name="Group 6210" transform="translate(104.957 4.606)">
                                    <path id="Path_27356" data-name="Path 27356" className="campable-logo-3" d="M847.673,71.5a4.053,4.053,0,1,1,.869-1.326h3.381a7.311,7.311,0,1,0-1.171,2.683Z"
                                          transform="translate(-837.48 -61.36)"/>
                                </g>
                                <g id="Group_6211" data-name="Group 6211" transform="translate(0 4.604)">
                                    <path id="Path_27357" data-name="Path 27357" className="campable-logo-3"
                                          d="M242.313,72.707a4.056,4.056,0,1,1,2.892-6.894l3.085-1.35a7.3,7.3,0,1,0-.007,8.385L245.2,71.493A4.032,4.032,0,0,1,242.313,72.707Z"
                                          transform="translate(-235.01 -61.35)"/>
                                </g>
                                <g id="Group_6212" data-name="Group 6212" transform="translate(28.963 4.528)">
                                    <path id="Path_27358" data-name="Path 27358" className="campable-logo-3"
                                          d="M404.751,75.584H401.26v-8a6.676,6.676,0,0,1,13.352,0v7.894H411.12V67.586a3.185,3.185,0,0,0-6.369,0Z" transform="translate(-401.26 -60.91)"/>
                                </g>
                                <g id="Group_6213" data-name="Group 6213" transform="translate(38.842 4.528)">
                                    <path id="Path_27359" data-name="Path 27359" className="campable-logo-3"
                                          d="M461.461,75.584H457.97v-8a6.676,6.676,0,1,1,13.352,0v7.894H467.83V67.586a3.185,3.185,0,0,0-6.369,0Z" transform="translate(-457.97 -60.91)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <path id="Path_27360" data-name="Path 27360" className="campable-logo-4"
                          d="M17.348,17.811a3.284,3.284,0,1,0,0,6.568H33.034A17.238,17.238,0,0,0,17.292,0a17.238,17.238,0,1,0,10.82,30.685H17.348A9.6,9.6,0,0,1,7.73,21.1h0a9.6,9.6,0,0,1,9.618-9.588h4.894v6.3H17.348Zm.962-8.187L17.25,7.784,16.191,9.625H12.4l4.854-8.43L22.1,9.625Z"/>
                </g>
            </g>
        </svg>

    );
}

CampableLogo.propTypes = {
    className: PropTypes.string,
};

CampableLogo.defaultProps = {
    className: null,
};
